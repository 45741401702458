import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby-plugin-intl";
import { concatClassNames as cn } from "@sys42/utils";

import {
  HighlightedText,
  Faqs,
  featureOverviewFeatures,
} from "@userbrain/website-ui";

import Layout from "../../layouts/layout";
import { GetStartedBox } from "../../components/GetStartedBox";
import { HeaderOverlap } from "../../components/HeaderOverlap";
import { FeatureOverview } from "../../components/FeatureOverview";
import { ThreeColumnsSteps } from "../../components/ThreeColumnsSteps";
import { Slider } from "../../components/Slider";

import imageIconShareInsights from "@userbrain/website-ui/images/feature-icon-share-insights.svg";
import imageCreateClip from "../../images/createClip.jpg";
import imageOrganizeClipsWithTags from "../../images/organizeClipsWithTags.jpg";
import imageShareClip from "../../images/shareClip.jpg";

import imageDashboardAccountMenu from "../../images/dashboardAccountMenu.jpg";
import imageDashboardTeamMembers from "../../images/dashboardTeamMembers.jpg";
import imageDashboardAddNewTeamMembers from "../../images/dashboardAddNewTeamMember.jpg";

import * as helperStyles from "../helpers.module.css";

export default function HighlightClips() {
  return (
    <Layout
      activeMenuItem={"features/collaborate"}
      title={"Highlight Clips"}
      metaDescription={
        "Effortlessly capture and share key moments from user tests with short clips, making your findings more engaging and actionable."
      }
    >
      <article>
        <HeaderOverlap
          isDisplayCta
          featureIcon={imageIconShareInsights}
          featureIconAlt={"Highlight Clips"}
          overline={"Highlight Clips"}
          title={
            <>
              <span className={helperStyles.h1Serif}>Create Clips and </span>
              share your insights
            </>
          }
          titleWidth={"9em"}
          buttonSubtext={"No credit card required"}
          staticImage={(className) => (
            <StaticImage
              src={"../../images/shareClipModal.jpg"}
              className={className}
              alt={"Modal with button that copies clip link"}
            />
          )}
        />
        <div
          className={cn(
            helperStyles.container,
            helperStyles.wavyLineBackground
          )}
        >
          <ThreeColumnsSteps
            className={helperStyles.spacingLg}
            title={"Get the right information to the right people"}
            titleWidth={"12em"}
            //
            col1Heading={"Mark highlights and create clips"}
            col1Text={
              "Create a clip as soon as you spot something interesting in your user tests. Or simply take an AI note and turn it into a clip."
            }
            col1Image={imageCreateClip}
            col1Alt={"Text input to enter clip description"}
            //
            col2Heading={"Organize clips with #tags"}
            col2Text={
              "You can filter clips by tasks, testers and tags, like #user_frustration, #usability_problem, #key_takeaway, and more."
            }
            col2Image={imageOrganizeClipsWithTags}
            col2Alt={"List of checkboxes with tags to filter clips by"}
            //
            col3Image={imageShareClip}
            col3Heading={"Share clips with your team"}
            col3Text={
              "Share a list of clips tagged #key_takeaway or #positive_feedback with your team, or send a clip to someone with a simple link."
            }
            col3Alt={"Shared clip description with button to view entire video"}
          />
        </div>

        <Slider
          className={helperStyles.spacingLg}
          title={"Add team members and assign roles"}
          subtitle={"How it works:"}
          slides={[
            {
              title: "Go to your Account.",
              imageSrc: imageDashboardAccountMenu,
              imageAlt:
                "Add team member, first step: Mouse pointer clicking on Account/Team members",
            },
            {
              title: (
                <>
                  Select <i>Add team member</i>.
                </>
              ),
              imageSrc: imageDashboardTeamMembers,
              imageAlt:
                "Add team member, second step: Mouse pointer clicking on add team member",
            },
            {
              title: "Insert their email, select role type & send invite.",
              imageSrc: imageDashboardAddNewTeamMembers,
              imageAlt:
                "Add team member, third step: Entering team member details",
            },
          ]}
        />

        <div className={helperStyles.container}>
          <Faqs
            className={helperStyles.spacingLg}
            questions={[
              {
                question: "How many clips are included with my subscription?",
                answer:
                  "Good news, all subscriptions include unlimited clips. You'll have access  to clips as long as your subscription is active. Please note, clips are  not available with our Pay-As-You-Go plan.",
              },
              {
                question: "How many team members can I invite?",
                answer: (
                  <>
                    The number of team members you can invite depends on your
                    current plan. You can find out more on our{" "}
                    <Link to="/pricing/">pricing page</Link>.
                  </>
                ),
              },
              {
                question: "What roles can I assign?",
                answer: (
                  <>
                    You can assign team members as:
                    <ul>
                      <li>
                        Collaborators: can view, comment, share, and export test
                        videos.
                      </li>
                      <li>
                        Admins: can do all of the above, create and edit tests,
                        as well as buy and spend credits.
                      </li>
                    </ul>
                  </>
                ),
              },
            ]}
          />

          <GetStartedBox
            title={
              <>
                You want to{" "}
                <HighlightedText cursor>share your insights</HighlightedText>{" "}
                with your team?
              </>
            }
            titleWidth={"15em"}
            className={helperStyles.spacingLg}
          />
        </div>

        <div
          className={cn(
            helperStyles.container,
            helperStyles.container_featureOverview
          )}
        >
          <FeatureOverview
            className={helperStyles.spacingLg}
            title={
              "Userbrain features for easy, unmoderated remote user testing"
            }
            titleWidth={"16em"}
            features={featureOverviewFeatures}
          />
        </div>
      </article>
    </Layout>
  );
}
