import React, { useState, useEffect, useMemo } from "react";
import { Link } from "gatsby-plugin-intl";
import { accessibleOnClick, concatClassNames as cn } from "@sys42/utils";
import {
  ButtonLink,
  Headline2Sans,
  Headline3Sans,
} from "@userbrain/website-ui";
import * as styles from "./styles.module.css";

export function ThreeColumnsSteps(props) {
  const {
    title,
    subtitle,
    col1Image,
    col1Alt,
    col1Heading,
    col1Text,
    col1LinkTo,
    col1LinkHref,
    col1LinkText,
    col2Image,
    col2Alt,
    col2Heading,
    col2Text,
    col2LinkTo,
    col2LinkHref,
    col2LinkText,
    col3Image,
    col3Alt,
    col3Heading,
    col3Text,
    col3LinkTo,
    col3LinkHref,
    col3LinkText,
    titleWidth,
    className,
    alternativeStyle,
  } = props;

  const [activeSlide, setActiveSlide] = useState(-1);
  const [animationEnabled, setAnimationEnabled] = useState(true);

  const steps = useMemo(() => [0, 1, 2], []);

  function handleClickStep(step) {
    setActiveSlide(step);
    setAnimationEnabled(false);
  }

  useEffect(() => {
    if (animationEnabled) {
      const interval = setInterval(() => {
        setActiveSlide((prevState) => {
          if (prevState === steps.length - 1) {
            setTimeout(() => setActiveSlide(0), 0);
            return -1;
          } else {
            return prevState + 1;
          }
        });
      }, 5000);
      setActiveSlide(0);
      return () => {
        clearInterval(interval);
      };
    }
  }, [animationEnabled, steps]);

  return (
    <div
      className={cn(
        className,
        styles.threeColumnsDefault,
        alternativeStyle && styles.threeColumnsDefault_alternative
      )}
    >
      <Headline2Sans className={styles.title} style={{ maxWidth: titleWidth }}>
        {title}
      </Headline2Sans>
      <p className={styles.subtitle}>{subtitle}</p>
      <div className={styles.autoSliderNav}>
        {steps.map((step) => (
          <div
            key={step}
            {...accessibleOnClick(() => handleClickStep(step))}
            className={cn(
              styles.autoSliderNavItem,
              activeSlide === step &&
                animationEnabled &&
                styles.autoSliderNavItem_active,
              (activeSlide > step ||
                (!animationEnabled && activeSlide === step)) &&
                styles.autoSliderNavItem_done
            )}
          >
            {step + 1}
          </div>
        ))}
      </div>
      <div
        className={styles.columns}
        style={{
          left: `calc(${-100 * Math.max(0, activeSlide) + "%"} - ${
            32 * Math.max(0, activeSlide)
          }px)`,
        }}
      >
        <div className={styles.column}>
          <div className={styles.stepNumber}>1</div>
          <img src={col1Image} alt={col1Alt} />
          <Headline3Sans className={styles.columnHeading}>
            {col1Heading}
          </Headline3Sans>
          <p className={styles.text}>{col1Text}</p>
          {col1LinkText && (
            <ButtonLink
              className={styles.link}
              customTag={col1LinkTo ? Link : "a"}
              to={col1LinkTo}
              href={col1LinkHref}
            >
              {col1LinkText}
            </ButtonLink>
          )}
        </div>
        <div className={styles.column}>
          <div className={styles.stepNumber}>2</div>
          <img src={col2Image} alt={col2Alt} />
          <Headline3Sans className={styles.columnHeading}>
            {col2Heading}
          </Headline3Sans>
          <p className={styles.text}>{col2Text}</p>
          {col2LinkText && (
            <ButtonLink
              className={styles.link}
              customTag={col2LinkTo ? Link : "a"}
              to={col2LinkTo}
              href={col2LinkHref}
            >
              {col2LinkText}
            </ButtonLink>
          )}
        </div>
        <div className={styles.column}>
          <div className={styles.stepNumber}>3</div>
          <img src={col3Image} alt={col3Alt} />
          <Headline3Sans className={styles.columnHeading}>
            {col3Heading}
          </Headline3Sans>
          <p className={styles.text}>{col3Text}</p>
          {col3LinkText && (
            <ButtonLink
              className={styles.link}
              customTag={col3LinkTo ? Link : "a"}
              to={col3LinkTo}
              href={col3LinkHref}
            >
              {col3LinkText}
            </ButtonLink>
          )}
        </div>
      </div>
    </div>
  );
}
